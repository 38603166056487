<template>
  <main class="text-center p-5 w-full md:w-3/6 m-auto">
    <form @submit.prevent="submitPasswordReset" class="w-100">
      <h1 class="mb-3 font-semibold text-xl">Confirmation</h1>
      <p>We emailed you a four-digit code to reset your password</p>
      <div class="mt-4 form__div text-black">
        <input
          type="number"
          class="form__input"
          id="code"
          v-model="code"
          placeholder=" "
          required
        />
        <label for="code" class="form__label">Code</label>
      </div>
      <div v-if="showErrorMessage" class="alert alert-danger">
        Invalid verification code
      </div>
      <button
        class="w-full bg-mindaro text-darkBlue p-2 font-semibold mt-5"
        type="submit"
      >
        Send
      </button>
    </form>
  </main>
</template>

<script setup>
import { ref } from "vue";

const code = ref(null);
const showErrorMessage = ref(false);

const emit = defineEmits(["validateCode"]);

function submitPasswordReset() {
  return emit("validateCode", code.value);
}
</script>
