<template>
  <main class="text-center p-5 md:w-3/6 m-auto">
    <form @submit.prevent="submitPasswordResetRequest">
      <div class="form__div mt-2 mb-4 text-black">
        <input
          type="email"
          class="form__input"
          id="email"
          v-model="email"
          placeholder=" "
        />
        <label for="email" class="form__label">Email</label>
      </div>
      <button
        class="w-full bg-mindaro text-darkBlue p-2 font-semibold mt-5"
        type="submit"
      >
        Send
      </button>
    </form>
  </main>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  userEmail: {
    type: String,
    default: "",
  },
});

const email = ref(null);

watch(() => props.userEmail, (newValue) => {
  email.value = newValue || null;
}, { immediate: true });

const emit = defineEmits(["resetPassword"]);

function submitPasswordResetRequest() {
  return emit("resetPassword", email.value);
}
</script>
