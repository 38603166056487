<template>
  <main class="text-center p-5 w-3/6 m-auto">
    <form @submit.prevent="submitResetPassword">
      <h1 class="mb-3 font-semibold text-xl">New password</h1>
      <p>Enter your new password below</p>
      <div class="form__div my-8 text-black">
        <input
          type="password"
          class="form__input"
          id="password"
          v-model="password"
          placeholder=" "
          minlength="8"
          @click="changeShowPasswordReq()"
          @focus="changeShowPasswordReq()"
          required
        />
        <label for="password" class="form__label">New Password</label>
        <div
          v-if="showPasswordReq"
          class="absolute -left-72 -top-2/3 p-4 border-2 bg-white"
        >
          <span
            class="absolute top-1/4 w-4 h-4 transform -rotate-45 border-b-2 border-r-2 z-10 bg-white"
            style="left: 98%"
          ></span>
          <p>To make your password stronger:</p>
          <ol class="list-disc m-2 text-left">
            <li>Min 8 characters</li>
            <li>Min 1 number</li>
            <li>Min 1 capital letter</li>
            <li>Min 1 special character</li>
          </ol>
        </div>
      </div>
      <div class="form__div mb-4 text-black">
        <input
          v-model="confirmPassword"
          type="password"
          class="form__input"
          id="repeat"
          placeholder=" "
          minlength="8"
          required
        />
        <label for="repeat" class="form__label">Confirm Password</label>
      </div>
      <button
        class="w-full bg-mindaro text-darkBlue p-2 font-semibold mt-5"
        type="submit"
      >
        Send
      </button>
    </form>
    <div v-if="showErrorMessage" class="text-center text-danger mt-2">
      <em class="fa-solid fa-circle-exclamation"></em>
      <span class="ml-1">
        {{ errorMessage }}
      </span>
    </div>
  </main>
</template>

<script setup>
import { ref, watch } from "vue";

const password = ref(null);
const confirmPassword = ref(null);
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$ %^&*-.]).{8,}$/;
const showErrorMessage = ref(false);
const errorMessage = ref(null);
const showPasswordReq = ref(false);

const emit = defineEmits(["submitNewPassword"]);

watch(password, (newPassword) => {
  const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$ %^&*-.]).{8,}$/;
  if (reg.test(newPassword)) {
    showPasswordReq.value = false;
  }
});

function submitResetPassword() {
  if (validatePassword()) {
    emit("submitNewPassword", password.value);
  }
}

function validatePassword() {
  if (password.value !== confirmPassword.value) {
    showError("Passwords do not match.");
    return false;
  }

  if (password.value.length < 8) {
    showError(
      "This password is too short. It must contain at least 8 characters."
    );
    return false;
  }

  if (!passwordRegex.test(password.value)) {
    showError("Password does not satisfy the requirements.");
    return false;
  }
  return true;
}

function showError(errorMess) {
  showErrorMessage.value = true;
  errorMessage.value = errorMess;
}

function changeShowPasswordReq() {
  showPasswordReq.value = true;
}
</script>
